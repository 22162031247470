import { Box, Drawer, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import { DRAWER_WIDTH_SM } from '@linx-ui/shared/constants';
import { useKeyboardTrigger } from '@linx-ui/shared/context';
import { CircularLoader } from '../loaders';
import {
  ActionBox,
  ActionBoxLeft,
  ActionBoxRight,
  ButtonCnt,
  ContainerBox,
  ContentBox,
  HeaderBox,
  HeaderBoxLeft,
  HeaderBoxRight,
  StyledArrowIconButton,
  StyledBackdrop,
  drawerWidth
} from './DrawerContainer.styled';
import { type DrawerContainerProps } from './types';

const _DrawerContainer = ({
  width = DRAWER_WIDTH_SM,
  openDrawer,
  toggle,
  loader = false,
  title,
  children,
  headerComponents,
  triggerBtn,
  actionBtnsRight,
  actionBtnsLeft,
  closeActionCallback,
  disableClose,
  dataTestId,
  shortcutId
}: DrawerContainerProps) => {
  const theme = useTheme();
  const TiggerBtn = triggerBtn;

  const hasBottomActionBar = !!actionBtnsLeft?.length || !!actionBtnsRight?.length;
  useKeyboardTrigger(shortcutId, toggle);

  return (
    <>
      {TiggerBtn && <TiggerBtn onClick={toggle} />}
      <Drawer
        PaperProps={{
          sx: { ...drawerWidth(width), background: theme.palette.background.paper }
        }}
        variant="temporary"
        anchor="right"
        open={openDrawer}
        data-testid={dataTestId}
      >
        <ContainerBox>
          <>
            <HeaderBox>
              <HeaderBoxLeft>
                <StyledArrowIconButton toggleDrawer={closeActionCallback ?? toggle} disabled={Boolean(disableClose)} />
                <Typography variant="h5">{title}</Typography>
              </HeaderBoxLeft>

              {(headerComponents?.length || 0) > 0 && (
                <HeaderBoxRight>
                  {headerComponents?.map((headerComponent) => {
                    return headerComponent;
                  })}
                </HeaderBoxRight>
              )}
            </HeaderBox>
            <ContentBox bottomActionbar={hasBottomActionBar}>{children}</ContentBox>
            {hasBottomActionBar && (
              <ActionBox>
                <ActionBoxLeft>
                  {actionBtnsLeft?.map((action, index) => <ButtonCnt key={`left-action-${index}`}>{action}</ButtonCnt>)}
                </ActionBoxLeft>

                <ActionBoxRight>
                  {actionBtnsRight?.map((action, index) => (
                    <ButtonCnt key={`right-action-${index}`}>{action}</ButtonCnt>
                  ))}
                </ActionBoxRight>
              </ActionBox>
            )}
          </>
          {loader && (
            <StyledBackdrop open={loader}>
              <Box width={width}>
                <CircularLoader />
              </Box>
            </StyledBackdrop>
          )}
        </ContainerBox>
      </Drawer>
    </>
  );
};

export const DrawerContainer = memo(_DrawerContainer) as typeof _DrawerContainer;
