import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, type SelectChangeEvent } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useApiContext } from '@linx-ui/shared/components/customDataGrid';
import type { TableFilterOption, TableFilterProps } from './types';

const TableFilter: React.FC<TableFilterProps> = React.memo(
  ({ value = '', onChange, options, columnName, label, disabled, size = 'small' }: TableFilterProps) => {
    const theme = useTheme();
    const id = `${label.toLowerCase().replace(' ', '-')}-filter-select`;
    const [filterValue, setFilterValue] = useState(value);
    const { publicApi } = useApiContext();

    const allColumns = useMemo(() => publicApi.getAllColumns(), [publicApi, publicApi.getAllColumns()]);

    const columnToFilter = useMemo(() => allColumns.filter((column) => column.id === columnName)[0], [allColumns]);

    const handleChange = (event: SelectChangeEvent<string | number>) => {
      setFilterValue(event.target.value);
      onChange?.(event.target.value);
    };

    useEffect(() => {
      columnToFilter?.setFilterValue(filterValue);
    }, [filterValue]);

    return (
      <Box sx={{ width: 120 }}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel shrink id="table-filter" sx={{ top: theme.spacing(-1) }}>
            {label}
          </InputLabel>
          <Select<string | number>
            displayEmpty
            id={id}
            data-testid={id}
            value={filterValue}
            label={label}
            size={size}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {options.map((option: TableFilterOption) => {
              return (
                <MenuItem key={nanoid()} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  }
);

TableFilter.displayName = 'Table Filter';

export { TableFilter };
