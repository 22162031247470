import { type ColumnDef } from '@tanstack/react-table';
import moment from 'moment-timezone';
import { camelCaseToSpaceSeperated, dashYYYYMMDDToSlashMMDDYYYY, getDateTimeDisplayValue } from '@linx-ui/shared/utils';
import { type History } from './types';

const yyyymmddFormatColumns = ['dateOfBirth'];

const timestampColumns = ['createdTime', 'lastUpdatedTime'];

export const getFormattedChangeHistoryValue = (value?: string, propertyName?: string) => {
  const formattedValue = (() => {
    try {
      const parsedValue = value && JSON.parse(value);
      return Array.isArray(parsedValue) ? JSON.stringify(parsedValue, null, 4) : (parsedValue ?? '').toString();
    } catch (e) {
      return value ?? '';
    }
  })();
  if (formattedValue && propertyName && timestampColumns.includes(propertyName)) {
    return moment(formattedValue).format('lll');
  } else if (formattedValue && propertyName && yyyymmddFormatColumns.includes(propertyName)) {
    return dashYYYYMMDDToSlashMMDDYYYY(formattedValue);
  } else {
    return formattedValue;
  }
};

const columns: Array<ColumnDef<History>> = [
  {
    accessorKey: 'eventTime',
    cell: (info) => getDateTimeDisplayValue(info.getValue() as string) ?? '-',
    header: 'Last updated at',
    size: 140,
    enableSorting: false
  },
  {
    accessorKey: 'displayName',
    header: 'Changed By',
    size: 180,
    enableSorting: false
  },
  { accessorKey: 'eventType', header: 'Operation', size: 100 },
  {
    accessorFn: (row) => camelCaseToSpaceSeperated(row.propertyName),
    header: 'Field Name',
    size: 180,
    enableSorting: false
  },
  {
    accessorFn: (row) => getFormattedChangeHistoryValue(row.oldValue, row.propertyName),
    header: 'Old Value',
    size: 180,
    enableSorting: false
  },
  {
    accessorFn: (row) => getFormattedChangeHistoryValue(row.newValue, row.propertyName),
    header: 'New Value',
    size: 180,
    enableSorting: false
  }
];

export { columns };
