import { useMemo } from 'react';
import { useDynamicHeight } from '@linx-ui/shared/hooks/useDynamicHeight';
import { CustomDataGrid } from '../customDataGrid';
import { TableHeader, TextStyled } from '../styles';
import { TableWrapper, Wrapper } from './Table.styled';
import { type TableProps } from './types';

const Table: React.FC<TableProps> = ({
  name,
  title,
  subtitle,
  columns,
  data,
  onRowClick,
  headerPrimaryAction,
  loading,
  onChangeRowSelection,
  rowIdentifier,
  selectedRowIds,
  enableSingleRowSelection,
  enableMultiRowSelection,
  enableGlobalFilter,
  enableHideColumns,
  enableRowExpansion,
  RowExpansionComponent,
  headerStyle,
  tableStyle,
  rowExpansionKey,
  components,
  defaultHiddenColumns,
  testId,
  onRowsScrollEnd,
  onSearch,
  onSort,
  searchPlaceholder,
  disableRowExpansionFn,
  disableRowSelectionFn,
  disableRowFn,
  enableSorting,
  noToolbar = false
}: TableProps) => {
  const [tableToolbarRef, toolbarHeight] = useDynamicHeight();
  const noHeader: boolean = useMemo(() => {
    return !headerPrimaryAction && !title;
  }, [title, headerPrimaryAction]);

  return (
    <Wrapper>
      {!noHeader && (
        <TableHeader style={headerStyle} ref={tableToolbarRef}>
          <div>
            <TextStyled variant="h5">{title}</TextStyled>
            {subtitle != null && <TextStyled variant="subtitle1">{subtitle}</TextStyled>}
          </div>
          {headerPrimaryAction}
        </TableHeader>
      )}

      <TableWrapper subtractHeight={toolbarHeight} style={tableStyle}>
        <CustomDataGrid
          noToolbar={noToolbar}
          name={name}
          testId={testId}
          components={components}
          rowExpansionKey={rowExpansionKey}
          disableRowSelectionFn={disableRowSelectionFn}
          disableRowFn={disableRowFn}
          onChangeRowSelection={onChangeRowSelection}
          enableGlobalFilter={enableGlobalFilter}
          data={data}
          enableSingleRowSelection={enableSingleRowSelection}
          enableMultiRowSelection={enableMultiRowSelection}
          columns={columns}
          loading={loading}
          rowIdentifier={rowIdentifier}
          selectedRowIds={selectedRowIds}
          onRowClick={onRowClick}
          enableHideColumns={enableHideColumns}
          enableRowExpansion={enableRowExpansion}
          RowExpansionComponent={RowExpansionComponent}
          defaultHiddenColumns={defaultHiddenColumns}
          onRowsScrollEnd={onRowsScrollEnd}
          onSearch={onSearch}
          onSort={onSort}
          searchPlaceholder={searchPlaceholder}
          disableRowExpansionFn={disableRowExpansionFn}
          enableSorting={enableSorting}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export { Table };
