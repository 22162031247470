import { styled } from '@mui/material';

export const ShortcutSection = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3),
  marginBottom: theme.spacing(2),
  fontWeight: 'bold'
}));

export const ShortcutItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  fontSize: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  minWidth: '560px'
}));
