import { Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import { MenuItemStyled } from '../styles';

export const UserMenuItem: FC<{ name?: string; type?: string; onClick?: () => void }> = ({
  name,
  type,
  onClick,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <MenuItemStyled cursor={onClick ? 'cursor' : 'auto'} onClick={onClick} {...rest}>
      <Typography variant="body1" fontWeight="700">
        {name}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {type}
      </Typography>
    </MenuItemStyled>
  );
};
