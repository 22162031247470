import {
  useForm,
  type DeepMap,
  type DeepPartial,
  type FieldErrors,
  type FieldValues,
  type UseFormProps
} from 'react-hook-form';
import type { ValidationError } from '@linx-ui/shared/types';

const useFormWrapperWithoutSteps = <Data extends FieldValues = Record<string, any>>(props: UseFormProps<Data> = {}) => {
  const formValues = useForm<Data>({ mode: 'onTouched', ...props });

  const { errors, touchedFields, isValid } = formValues.formState;

  const getValidationError = (key: string): ValidationError => {
    let hasError = false;
    let message = '';
    const keys = key.split('.');

    const error = keys.reduce((acc, curr) => {
      return acc?.[curr] as FieldErrors<Data>;
    }, errors);

    const isTouched = keys.reduce(
      (acc: Partial<Readonly<DeepMap<DeepPartial<Record<string, any>>, boolean>>>, curr: string) => {
        return acc?.[curr] as Partial<Readonly<DeepMap<DeepPartial<Record<string, any>>, boolean>>>;
      },
      touchedFields
    );

    hasError = !!error;
    message = (error as any)?.message;
    return { hasError: !!isTouched && hasError, message };
  };

  const checkIsValidFields = (keys: string[]) => {
    return keys.reduce((acc, key) => {
      const validationError = getValidationError(key);
      return acc && !validationError.hasError;
    }, true);
  };

  return {
    getValidationError,
    isValid,
    checkIsValidFields,
    ...formValues
  };
};

export { useFormWrapperWithoutSteps };
