import { Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { GridLayoutContainerWithSpacing2 } from '../gridLayout';
import type { DetailSectionWrapperProps } from './types';

export const DetailSectionWrapper: React.FC<DetailSectionWrapperProps> = ({
  children,
  title,
  titleColor,
  withoutDivider = false,
  ref
}) => {
  const theme = useTheme();
  return (
    <GridLayoutContainerWithSpacing2 ref={ref}>
      <Grid item>
        <Typography variant="subtitle2" color={titleColor || theme.palette.secondary.dark}>
          {title}
        </Typography>
      </Grid>
      {children}
      {!withoutDivider && (
        <Grid item width="100%">
          <Divider sx={{ width: '100%' }} />
        </Grid>
      )}
    </GridLayoutContainerWithSpacing2>
  );
};

DetailSectionWrapper.displayName = 'DetailSectionWrapper';
