import { AlertTitle, type SnackbarCloseReason } from '@mui/material';
import { nanoid } from 'nanoid';
import { Fragment } from 'react';
import { useGlobalContext } from '@linx-ui/shared/context';
import { StyledAlert, StyledDivider, StyledSnackbar } from './SnackBar.styled';

const SnackBar = () => {
  const { snackBarState, setSnackBarState } = useGlobalContext();
  const { open, title, messages, severity = 'success' } = snackBarState;

  const closeSnackBar = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };
  const handleSnackbarClose = (event: Event | React.SyntheticEvent<any>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackBar();
  };

  return open ? (
    <StyledSnackbar
      data-testid="toast-message"
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <StyledAlert variant="standard" onClose={closeSnackBar} severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {messages?.map((message: string, index: number) => {
          return (
            <Fragment key={nanoid()}>
              <span>{message}</span>
              {index < messages?.length - 1 ? <StyledDivider severity={severity} /> : null}
            </Fragment>
          );
        })}
      </StyledAlert>
    </StyledSnackbar>
  ) : null;
};

export { SnackBar };
