import { styled } from '@mui/material';
import * as ResizablePrimitive from 'react-resizable-panels';

export const StyledPanelGroup = styled(ResizablePrimitive.PanelGroup)<{
  className?: string;
}>(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  '&[data-panel-group-direction="vertical"]': {
    flexDirection: 'column'
  }
}));

export const ResizablePanel = ResizablePrimitive.Panel;

export const HandleContainer = styled(ResizablePrimitive.PanelResizeHandle)<{
  withHandle?: boolean;
}>(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,

  '&::after': {
    content: "''",
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%',
    width: '1px',
    transform: 'translateX(-50%)'
  },

  '&[data-panel-group-direction="vertical"]': {
    height: '1px',
    width: '100%',
    '&::after': {
      left: 0,
      height: '1px',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '> div': {
      transform: 'rotate(90deg)'
    }
  },
  '&:focus-visible': {
    ringOffset: '1px'
  }
}));

export const GripWrapper = styled('div')(({ theme }) => ({
  zIndex: 10,
  display: 'flex',
  width: '15px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2px',
  backgroundColor: theme.palette.background.default,
  svg: {
    width: '100%'
  }
}));
