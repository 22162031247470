import { Box, IconButton, Menu, MenuItem, styled, Tabs, Toolbar, useTheme, type TabsProps } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Children } from 'react';
import { DRAWER_WIDTH } from '@linx-ui/shared/constants';
import { type AppBarProps, type MenuProps } from './types';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  marginLeft: DRAWER_WIDTH,
  transition: theme.transitions.create(['margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open === true && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const AppBarStyled = ({ open, children }: AppBarProps): JSX.Element => {
  const theme = useTheme();
  return (
    <AppBar position="fixed" open={open} elevation={0} sx={{ borderBottom: 1, color: theme.palette.divider }}>
      {children}
    </AppBar>
  );
};

const HeaderTabsStyled = ({ value, onChange, children }: TabsProps): JSX.Element => (
  <Tabs
    sx={{ height: '64px', alignItems: 'end' }}
    variant="scrollable"
    orientation="horizontal"
    value={value}
    onChange={onChange}
    scrollButtons={false}
  >
    {children}
  </Tabs>
);

const MenuStyled = ({ anchorEl, id, open, onClose, children, ...rest }: MenuProps): JSX.Element => {
  const mItems = Children.toArray(children);
  return (
    <Menu
      {...rest}
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {mItems.map((mItem) => {
        return mItem;
      })}
    </Menu>
  );
};

const MenuItemStyled = styled(MenuItem)<{ cursor?: string }>(({ theme, cursor }) => {
  return {
    cursor: cursor ?? 'pointer',
    display: 'block',
    padding: '8px 16px',
    '&:nth-of-type(n+1)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    '&:last-child': {
      border: 'none'
    }
  };
});

const BoxStyled = styled(Box)(() => ({ display: 'flex' }));

const ToobarStyled = styled(Toolbar)(({ theme }) => ({ backgroundColor: theme.palette.background.paper }));

const EmptyBoxStyled = styled(Box)(() => ({ flexGrow: 1 }));

const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (props) => props !== 'open'
})<{ open: boolean }>(({ theme, open }) =>
  theme.unstable_sx({
    marginRight: 5,
    display: open ? 'none' : null
  })
);

const tabMinHeight = { minHeight: '64px' };

export {
  AppBar,
  AppBarStyled,
  BoxStyled,
  EmptyBoxStyled,
  HeaderTabsStyled,
  IconButtonStyled,
  MenuItemStyled,
  MenuStyled,
  tabMinHeight,
  ToobarStyled
};
