import { Box, Button, styled, TextField } from '@mui/material';

export const TextFieldStyled = styled(TextField)(() => {
  return {
    width: '176px',
    '.MuiInput-root': {
      '::before': {
        borderBottom: 'none'
      }
    }
  };
});

export const DropdownWrapper = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(1, 1.5, 1, 0.25),
    display: 'flex',
    alignItems: 'center'
  };
});

export const ButtonStyled = styled(Button)(({ theme }) => {
  return {
    padding: 0,
    minWidth: '48px',
    minHeight: '32px',
    color: theme.palette.text.primary
  };
});
