import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { alpha, Backdrop, Box, Button, IconButton, styled } from '@mui/material';

const ContainerBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 2, 1, 1),
  gap: theme.spacing(1),
  alignItems: 'center',
  height: '64px'
}));

const HeaderBoxLeft = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center'
}));

const HeaderBoxRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
  flex: 1
}));

const StyledArrowIconButton = (props: { toggleDrawer: () => void; disabled?: boolean }): JSX.Element => (
  <IconButton
    aria-label="close"
    onClick={props.toggleDrawer}
    sx={{ height: '48px', width: '48px' }}
    disabled={Boolean(props.disabled)}
  >
    <ChevronRightIcon
      sx={{
        color: 'primary.contrastText'
      }}
    />
  </IconButton>
);

const ContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bottomActionbar'
})<{ bottomActionbar?: boolean }>(({ bottomActionbar }) => ({
  height: `calc(100% - ${bottomActionbar ? 128 : 64}px)`,
  width: '100%'
}));

const ActionBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme.palette.divider}`,
  minHeight: '64px',
  paddingRight: theme.spacing(1.25),
  paddingLeft: theme.spacing(1.25)
}));

const ActionBoxLeft = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center'
}));

const ActionBoxRight = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1)
}));

const ButtonCnt = styled('div')(({ theme }) => ({
  margin: theme.spacing(1)
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  background: alpha(theme.palette.common.white, 0.2),
  zIndex: theme.zIndex.drawer + 1,
  justifyContent: 'right'
}));

const drawerWidth = (width: string) => {
  return { width: { xs: '100%', sm: '100%', md: width, lg: width } };
};
export {
  ContainerBox,
  HeaderBox,
  StyledArrowIconButton,
  ContentBox,
  ActionBox,
  StyledButton,
  StyledBackdrop,
  drawerWidth,
  ActionBoxLeft,
  ActionBoxRight,
  ButtonCnt,
  HeaderBoxLeft,
  HeaderBoxRight
};
