import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { TableBody, TableRow, Typography } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import React, { memo, useMemo } from 'react';
import { notUndefined } from '@tanstack/react-virtual';
import {
  NoResultFound,
  TableCellContent,
  TableCellLoading,
  TableCellNotFound,
  TableRowStyled
} from './CustomDataGrid.styled';
import { DragAlongCell } from './DragAlongCell';
import { LoadingSkeleton } from './Loading.component';
import type { DataGridBodyProps } from './types';

const _CustomDataGridBody: React.FC<DataGridBodyProps> = ({
  isRowClickable,
  virtualRows,
  rows,
  loading,
  useLoadingState,
  onRowClicked,
  getExpandedRowComponent,
  noDataFound,
  table,
  columnOrder,
  virtualizer,
  disableRowFn,
  NoDataFound = 'No data found'
}) => {
  const loader = useLoadingState && loading;
  const [beforePadding, afterPadding] = useMemo(
    () =>
      virtualRows.length > 0
        ? [
            notUndefined(virtualRows[0]).start - virtualizer.options.scrollMargin,
            virtualizer.getTotalSize() - notUndefined(virtualRows[virtualRows.length - 1]).end
          ]
        : [0, 0],
    [virtualRows]
  );

  return (
    <TableBody>
      {beforePadding > 0 && (
        <tr>
          <td style={{ height: beforePadding }} />
        </tr>
      )}
      {!loader &&
        virtualRows.map((virtualRow, index) => {
          const row = rows[virtualRow.index];
          return (
            <React.Fragment key={'row-container-fragment-' + row.id}>
              <TableRowStyled
                data-index={virtualRow.index}
                clickable={isRowClickable && !disableRowFn?.(row.original)}
                disabled={!!disableRowFn?.(row.original)}
                onClick={(event) => onRowClicked(row.original, event)}
                ref={virtualizer.measure}
                hover
              >
                {row.getVisibleCells().map((cell) => {
                  // @ts-expect-error
                  const props = flexRender(cell.column.columnDef.cell, cell.getContext())?.props;
                  const cellValue = props?.getValue();
                  const isEmpty =
                    (props.cell.column.columnDef.accessorKey || props.cell.column.columnDef.accessorFn) &&
                    (cellValue === undefined || cellValue === null || cellValue === '');
                  return (
                    <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
                      <DragAlongCell cell={cell}>
                        <TableCellContent width={cell.column.getSize()}>
                          {isEmpty ? (
                            <Typography>-</Typography>
                          ) : (
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                          )}
                        </TableCellContent>
                      </DragAlongCell>
                    </SortableContext>
                  );
                })}
              </TableRowStyled>

              {getExpandedRowComponent(row, virtualRow)}
            </React.Fragment>
          );
        })}

      {noDataFound && !loading && (
        <TableRow data-testid="pms-data-grid-no-data">
          <TableCellNotFound colSpan={table.getAllLeafColumns().length}>
            <NoResultFound>{NoDataFound}</NoResultFound>
          </TableCellNotFound>
        </TableRow>
      )}

      {loading && (
        <TableRow data-testid="pms-data-grid-loader">
          <TableCellLoading colSpan={table.getAllLeafColumns().length}>
            <LoadingSkeleton />
          </TableCellLoading>
        </TableRow>
      )}
      {afterPadding > 0 && (
        <tr>
          <td style={{ height: afterPadding }} />
        </tr>
      )}
    </TableBody>
  );
};

export const CustomDataGridBody = memo(_CustomDataGridBody);
