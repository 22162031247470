import { Popover, styled } from '@mui/material';

export const StyledPopover = styled(Popover)<{ type?: string }>(({ type, theme }) => ({
  pointerEvents: type === 'hover' ? 'none' : 'auto',
  padding: theme.spacing(2)
}));

export const StyledImg = styled('img')(() => ({
  cursor: 'pointer'
}));

export const StyledInfoImg = styled('img')(() => ({
  display: 'block',
  maxWidth: '80vw'
}));
